<template>
	<div class="allbox">
		<div class="allchart">
			<div class="chart1">
				<div class="chart1title">
					<img src="@/assets/project/image2.png" />
					<span>任务处理进度</span>
				</div>
				<div class="chart1centent">
					<div class="box1" style="width: 48%;height: 90%;">
						<div class="box1text">
							<span>219</span>
							<p>所有任务</p>
						</div>
						<div id="yuanchart1" style="width: 100%;height: 100%;"></div>
					</div>
					<div class="chart1text">
						<div>
							<span>任务总数：</span>
							<p>219</p>
						</div>
						<div>
							<span>待解决数：</span>
							<p style="color: #F22E18;">219</p>
						</div>
						<div>
							<span>今日解决：</span>
							<p>219</p>
						</div>
						<div>
							<span>共解决数：</span>
							<p>219</p>
						</div>
					</div>
				</div>
			</div>
			<div class="projectfenbubox">
				<div class="chart1title">
					<img src="@/assets/project/image4.png" />
					<span>项目分布图</span>
				</div>
				<div id="projectfenbuechart" style="width: 100%;height: 90%;"></div>
			</div>
			
			<div class="chengyuanbox">
				<div class="topbox">
					<div class="onezhishi">
						<div style="background-color: #3286FF;"></div>
						<span>预期完成时间</span>
					</div>
					<div class="onezhishi">
						<div style="background-color: #3DC57C;"></div>
						<span>实际完成时间</span>
					</div>
				</div>
				<div class="chart1title">
						<img src="@/assets/project/image4.png" />
						<span>成员进度表</span>
					</div>
				<div id="chengyuanchart" style="width: 100%;height: 90%;"></div>
			</div>
		</div>
		<div class="box2">
			<div class="chart1title">
				<img src="@/assets/project/image6.png" />
				<span>指派给我的任务</span>
			</div>
			<div class="tablebox">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column prop="date" label="序号"></el-table-column>
					<el-table-column prop="name" label="ID"></el-table-column>
					<el-table-column prop="name" label="BUG描述"></el-table-column>
					<el-table-column prop="name" label="优先级"></el-table-column>
					<el-table-column prop="name" label="创建日期"></el-table-column>
					<el-table-column prop="name" label="截止日期"></el-table-column>
					<el-table-column prop="address" label="状态">
						<template slot-scope="scope">
							<div class="btnbox">
								<div class="statusbtn">
									待解决
								</div>
							</div>
							
						<!-- 	<div class="statusbtn">
								已解决
							</div> -->
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	data() {
		return {
			tableData: [
				{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				},
				{
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄'
				},
				{
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄'
				},
				{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄'
				}
			]
		};
	},
	name: 'projectOverview',
	created() {},
	mounted() {
		this.createChart1();
		this.createProjectFbEchart();
		this.chengyuanbEchart()
	},
	methods: {
		chengyuanbEchart() {
			var chartDom = document.getElementById('chengyuanchart');
			var myChart = echarts.init(chartDom);
			var option;
		
			option = {
				color: ['#3DC57C','#3286FF'],
				// center: ['100%', '100%'],
				grid: {
					left: '8%',
					top: '17%',
					height: '65%',
					width: '90%'
				},
				xAxis: {
					type: 'category',
					data: ['王小玉', '王小玉', '王小玉', '王小玉', '王小玉', '王小玉'],
					axisTick: {
						show: false
					},
					axisLine: {
						lineStyle: {
							color: '#DDDDDD',
							width: 0.8
						}
					},
		
					axisLabel: {
						textStyle: {
							color: '#999999' //坐标值文字得具体的颜色
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLine: {
						lineStyle: {
							color: '#999999',
							width: 0.8
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: '#EEEEEE'
						}
					}
				},
				series: [
					{
						barWidth: 15,
						labelLine: {
							show: true
						},
						itemStyle: {
							normal: {
								//柱形图圆角，初始化效果
								barBorderRadius: [15, 15, 0, 0]
							}
						},
						// barCategoryGap: '70%',
						// stack: 'one',
						data: [120, 200, 150, 80, 70, 110],
						type: 'bar'
					},
					{
						barWidth: 15,
						labelLine: {
							show: true
						},
						itemStyle: {
							normal: {
								//柱形图圆角，初始化效果
								barBorderRadius: [15, 15, 0, 0]
							}
						},
						barCategoryGap: '70%',
						// stack: 'one',
						data: [120, 200, 150, 80, 70, 110],
						type: 'bar'
					}
				]
			};
		
			option && myChart.setOption(option);
		},
		createProjectFbEchart() {
			var chartDom = document.getElementById('projectfenbuechart');
			var myChart = echarts.init(chartDom);
			var option;

			option = {
				color: ['#3286FF'],
				// center: ['100%', '100%'],
				grid: {
					left: '10%',
					top: '17%',
					height: '65%',
					width: '80%'
				},
				xAxis: {
					type: 'category',
					data: ['原型设计', 'UI设计', '开发中', '测试中', '交付中', '已验收'],
					axisTick: {
						show: false
					},
					axisLine: {
						lineStyle: {
							color: '#DDDDDD',
							width: 0.8
						}
					},

					axisLabel: {
						textStyle: {
							color: '#999999' //坐标值文字得具体的颜色
						}
					}
				},
				yAxis: {
					type: 'value',
					axisLine: {
						lineStyle: {
							color: '#999999',
							width: 0.8
						}
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
							color: '#EEEEEE'
						}
					}
				},
				series: [
					{
						barWidth: 15,
						labelLine: {
							show: true
						},
						itemStyle: {
							normal: {
								//柱形图圆角，初始化效果
								barBorderRadius: [15, 15, 0, 0]
							}
						},
						barCategoryGap: '70%',
						stack: 'one',
						data: [120, 200, 150, 80, 70, 110],
						type: 'bar'
					}
				]
			};

			option && myChart.setOption(option);
		},
	
		createChart1() {
			var chartDom = document.getElementById('yuanchart1');
			var myChart = echarts.init(chartDom);
			var option;

			option = {
				color: ['#3286FF', '#EFF3F6'],
				series: [
					{
						name: 'Access From',
						type: 'pie',
						radius: ['80%', '100%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '40',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data: [{ value: 1048 }, { value: 735 }]
					}
				]
			};

			option && myChart.setOption(option);
		}
	}
};
</script>

<style scoped lang="scss">
	.onezhishi {
		display: flex;
		align-items: center;
		margin-top: 0.375em;
		div {
			width: 0.5em;
			height: 0.5em;
			border-radius: 50%;
			margin: 0 0.625em 0 0;
		}
		span {
			font-size: 0.75em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #999999;
		}
	}
	.topbox {
		top: 1.275em;
		right: 1.5em;
		position: absolute;
	}
	.chengyuanbox {
		position: relative;
		padding: 0.9375em 0 0 1.1875em;
		width: 41%;
		height: 100%;
		background-color: #ffffff;
	}
	.btnbox {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.statusbtn {
		width: 3.95em;
		height: 1.5em;
		background: rgba(242, 46, 24, 0.1);
		border-radius: 0.75em 0 0.75em 0.75em;
		border: 1px solid rgba(242, 46, 24, 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.875em;
		font-family: PingFang-SC-Regular, PingFang-SC;
		font-weight: 400;
		color: #F22E18;
	}
	.tablebox {
		margin: 1.125em 0 0;
	}
	::v-deep {
		.cell {
			text-align: center;
		}
		tr {
			font-size: 0.975em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 400;
			color: #868e99;
			 
		}
		th {
			background-color: #f6f7fb !important;
			font-size: 0.975em;
			font-family: PingFang-SC-Medium, PingFang-SC;
			font-weight: 500;
			color: #5b6271;
		}
	}
.box2 {
	width: 100%;
	padding: 20px;
	margin: 0.9375em 0 0;
	background-color: #ffffff;
}
.projectfenbubox {
	margin: 0 0.9375em 0 0;
	padding: 0.9375em 0 0 1.1875em;
	width: 38%;
	height: 100%;
	background-color: #ffffff;
}
.huikuanbox {
	margin: 0 0.9375em 0 0;
	padding: 0.9375em 0 0 1.1875em;
	width: 21%;
	height: 100%;
	background-color: #ffffff;
}
.chart1title {
	display: flex;
	align-items: center;
	img {
		width: 1em;
		height: 1em;
		margin: 0 0.625em 0 0;
	}
	span {
		font-size: 1.125em;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #303030;
	}
}
.box1text {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #808080;
	}
	span {
		font-weight: 600;
		font-size: 1.25em;
		font-family: MicrosoftYaHei;
		color: #303030;
	}
}
#yuanchart1 {
	position: absolute;
}
#yuanchart2 {
	position: absolute;
}
.box1 {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
.chart1text {
	margin: 0 0 0 1.575em;
	div {
		margin: 0.75em 0;
		display: flex;
		align-items: center;
		p {
			font-size: 1em;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
		}
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #808080;
		}
	}
}
.chart1centent {
	display: flex;
	align-items: center;
	// justify-content: center;
	width: 100%;
	height: 90%;
}
// 1880
.chart1 {
	margin: 0 0.9375em 0 0;
	width: 19%;
	height: 100%;
	background-color: #ffffff;
	padding: 0.9375em 0 0 1.1875em;
	// display: flex;
	// align-items: center;
	// justify-content: center;
}
.allchart {
	display: flex;
	align-items: center;
	height: 15vw;
	min-height: 150px;
}
.allbox {
	width: 100%;
	padding: 0.9375em 0 0;
}
@media screen and (max-width: 1920px) {
	.allbox {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.allbox {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.allbox {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.allbox {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.allbox {
		font-size: 12px;
	}
}
</style>
