<template>
	<div>
	
		<personal-project-overview v-if="index == 1"></personal-project-overview>
		<teaml-project-overview v-if="index == 2"></teaml-project-overview>
	</div>
</template>

<script>
	import personalProjectOverview from "./component/personalProjectOverview.vue"
	import teamlProjectOverview from "./component/teamlProjectOverview.vue"
	export default {
		data() {
			return {
				index: 1
			}
		},
		components: {
			personalProjectOverview,
			teamlProjectOverview
		},
	}
</script>

<style scoped>

</style>